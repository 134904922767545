// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import GlobalOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/GlobalOutlined';
import DashboardOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/DashboardOutlined';
import TableOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/TableOutlined';
import SettingOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/SettingOutlined';
import BarChartOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/BarChartOutlined';
import DollarOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/DollarOutlined';
import LineChartOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/LineChartOutlined';
import ConsoleSqlOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/ConsoleSqlOutlined';
import BlockOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/BlockOutlined';
import EyeOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/EyeOutlined';
import PieChartOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/PieChartOutlined';
import FacebookOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/FacebookOutlined';
import CodeOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/CodeOutlined';
import UserAddOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/UserAddOutlined';
import SolutionOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import CustomerServiceOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/CustomerServiceOutlined';
import UnorderedListOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/UnorderedListOutlined';
import AlipayOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/AlipayOutlined';
import HeatMapOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/HeatMapOutlined';
import FileOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/FileOutlined';
import AlertOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/AlertOutlined';
import UserOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/UserOutlined';
import AuditOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/AuditOutlined';
import CloudOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/CloudOutlined';
import RobotOutlined from '/builds/dc/frontend/leyidc/node_modules/@ant-design/icons/es/icons/RobotOutlined';
export default { GlobalOutlined, DashboardOutlined, TableOutlined, SettingOutlined, BarChartOutlined, DollarOutlined, LineChartOutlined, ConsoleSqlOutlined, BlockOutlined, EyeOutlined, PieChartOutlined, FacebookOutlined, CodeOutlined, UserAddOutlined, SolutionOutlined, CustomerServiceOutlined, UnorderedListOutlined, AlipayOutlined, HeatMapOutlined, FileOutlined, AlertOutlined, UserOutlined, AuditOutlined, CloudOutlined, RobotOutlined };
